<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div
      v-else
      class="register-bg d-flex align-items-center justify-content-center"
    >
      <div class="w-100">
        <b-row class="justify-content-center align-items-center m-0">
          <b-col md="9">
            <b-row class="header-panel">
              <b-col>
                <p>ยินดีต้อนรับ</p>
              </b-col>
            </b-row>
            <b-row class="body-panel justify-content-center  card-container">
              <b-col md="5" class="bg-black p-0">
                <div class="picture"></div>
              </b-col>
              <b-col md="7" class="px-md-0">
                <div
                  class="p-3 register-box d-flex flex-column justify-content-center align-items-center"
                >
                  <p class="text-title">ยินดีต้อนรับ</p>
                  <div
                    class="data-box d-flex flex-column justify-content-center align-items-center"
                  >
                    <p class="text-title">
                      {{
                        form.isRegister == "true"
                          ? "ข้อมูลที่ใช้ในการลงทะเบียน"
                          : "อัพเดตข้อมูลสำเร็จ"
                      }}
                    </p>

                    <div class="w-100 text-black mt-4" v-if="form.isRegister == 'true'">
                      <b-row class="w-100">
                        <b-col md="5">
                          <p>ชื่อ-นามสกุล:</p>
                        </b-col>
                        <b-col>
                          <p> {{ form.name }}</p>
                        </b-col>
                      </b-row>
                      <b-row class="w-100">
                        <b-col md="5">
                          <p>รหัสสมาชิก:</p>
                        </b-col>
                        <b-col>
                          <p>{{ form.memberId }}</p>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";

import { mapGetters } from "vuex";

export default {
  components: {
    OtherLoading,
  },
  name: "NewCustomer",
  data() {
    return {
      isLoading: true,
      now: null,
      form: {
        memberId: "",
        name: "",
        isRegister: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    this.isLoading = false;

    this.form.memberId = this.$route.query.memberId;
    this.form.name = this.$route.query.name;
    this.form.isRegister = this.$route.query.isRegister;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.register-bg {
  background-image: url(./../../../../assets/images/icons/register-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.header-panel {
  text-align: center;
  padding: 10px;
  color: #fff;
  font-size: 20px;
  background-color: var(--primary-color);
}
::v-deep .body-panel {
  background-color: #ffffff;
  border: 3px solid var(--primary-color) !important;
  .text-title {
    text-align: center;
    color: var(--primary-color);
    padding: 10px 0;
    font-size: 20px;
  }
  .text-title-input {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
  }
  .picture {
    width: 100%;
    height: 100%;
    background-color: white;
    // background-image: url(./../../../../assets/images/icons/register_banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
  .btn-link {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    text-decoration: underline;
    padding: 0;
  }
  .desc-privacy {
    font-size: 12px;
  }
  .privacy-txt {
    color: #707070;
    display: inline;
    font-size: 12px;
    a {
      color: #707070 !important;
      text-decoration: underline;
    }
  }
  .register-box {
    min-height: 670px;
  }
  .data-box {
    background-color: #f1b6c6;
    padding: 50px;
    margin: 5%;
    border-top-left-radius: 30%;
    border-bottom-right-radius: 30%;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
    position: relative;
    height: 250px;
    width: 400px;
  }
  .card-container {
  max-height: 80vh;
  overflow: auto;
}

}
</style>
